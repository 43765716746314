import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo";
import ContactSection from '../components/contact-section'
import ClientSection from '../components/clients'
import TestimonialSection from '../components/testimonial'
import PricingSection from '../components/pricing-section-new'
import CounterSection from '../components/counter'
import FeatureSection from '../components/feature'
import ServicesSection from '../components/services'
import VideoSection from '../components/video'
//import { Container, Row} from 'reactstrap';

const IndexPage = () => (
    <Layout headerContent={true}>
        <SEO title="Home" />
        <FeatureSection/>
        <ServicesSection/>
        <VideoSection/>

        <PricingSection/>
        <TestimonialSection/>
        <ClientSection/>
        <CounterSection/>
        <ContactSection/>
    </Layout>
)

export default IndexPage
