import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const Client = () => {
	const query = useStaticQuery(
	graphql `
		query {
			allNotableclientsJson {
				edges {
					node {
						title
						desc
					}
				}
			}
			allStrapiClients(sort: {fields: created_at, order: DESC}) {
			    edges {
			      node {
			        link
			        client_image {
			          childImageSharp {
			            fluid {
			              src
			            }
			          }
			        }
			      }
			    }
			}
		}
	`)
	let clientHeader = [];
	query.allNotableclientsJson.edges.forEach(({node})=>{
		clientHeader.push(node);
	})
	clientHeader = clientHeader[0];
	let clients = [];
	query.allStrapiClients.edges.forEach(({node})=>{
		clients.push(node)
	})
	return (
		<div id="clients" className="section-padding bg-gray">
		  	<div className="container">
		    	<div className="section-header text-center">
		      		<h2 className="section-title wow fadeInDown">{clientHeader.title}</h2>
		      		<p>{clientHeader.desc}</p>
		    	</div>
		    	<div className="row text-align-">
		    		{
		    			clients.map((item, index)=> {
		    				return (
		    					<div className="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" key={`key_${index}`}>
		    					  	<div className="client-item-wrapper">
		    					    	<a href={item.link}><img className="img-fluid" src={item.client_image ? item.client_image.childImageSharp.fluid.src: null} alt=""/></a>
		    					  	</div>
		    					</div>
		    				)
		    			})
		    		}
		    	</div>
		  	</div>
		</div>
	)
}

export default Client