import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

const Counter = ()=>{
	const query = useStaticQuery(graphql`
		query {
			allCountersJson {
				edges {
					node {
						icon
						label
						counter
					}
				}
			}
		}
	`)
	let counter = [];
	query.allCountersJson.edges.forEach((item)=>{
		counter.push(item.node)
	})
	return (
		<section id="counter" className="section-padding">
			<div className="overlay"></div>
			<div className="container">
				<div className="row justify-content-between">
					<div className="col-lg-12 col-md-12 col-xs-12">
						<div className="row">
						{
							counter.map((item, index)=>{
								return(
									<div className="col-lg-3 col-md-6 col-xs-12" key={`key_${index}`}>
										<div className="counter-box wow fadeInUp">
											<div className="icon-o">
												<i className={`lni-${item.icon}`}></i>
											</div>
											<div className="fact-count">
												<h3><span className="counter">{item.counter}</span></h3>
												<p>{item.label}</p>
											</div>
										</div>
									</div>
								)
							})
						}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Counter