import React, { useState } from 'react';
import {  Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const Video = (props) => {
	const {
	    buttonLabel,
	    className
	} = props;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	return (
		<section className="video-promo section-padding">
			<div className="overlay"></div>
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12">
						<div className="video-promo-content text-center wow fadeInUp">
							<a className="video-popup" onClick={toggle}><i className="lni-film-play"></i></a>
							<h2 className="mt-3 wow zoomIn" data-wow-duration="1000ms" data-wow-delay="100ms">Watch Video</h2>
						</div>
					</div>
				</div>
			</div>
	        <Modal isOpen={modal} toggle={toggle} className={`vidoes-section modal-dialog modal-lg ${className}`}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody className="vidoes-section-body">
                  <iframe className="mfp-iframe" src="//www.youtube.com/embed/yP6kdOZHids?autoplay=1" frameBorder="0" allowFullScreen={true}></iframe>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
		</section>
	)
}

export default Video;