import React from 'react';
//import addToMailchimp from 'gatsby-plugin-mailchimp'
import { StaticQuery, graphql } from "gatsby"
import { UncontrolledAlert } from 'reactstrap';
import emailjs from 'emailjs-com';

import { css } from "@emotion/core";
// First way to import
//import { ClipLoader } from "react-spinners";
// Another way to import. This is recommended to reduce bundle size
import ScaleLoader from "react-spinners/ScaleLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 2;
  border-color: red;
`;

class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.validEmailRegex = this.validEmailRegex.bind(this);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            company_name: '',
            company_website_url: '',
            amazon_store_url: '',
            ebay_store_url: '',
            notes: '',
            handleSubmitsuccess: false,
            validationError: false,
            validationContent: 'Validation error(s) occured!',
            loading: false,
            errors: {
                first_name: null,
                last_name: null,
                email: null,
                company_name: null
            }
        };
    }
    validEmailRegex(){
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re
    }

    async handleSubmit(e){
        e.preventDefault();
        let validEmailRegex = this.validEmailRegex();
        let errors = this.state.errors;
        for(let prop in this.state){
            if(Object.keys(this.state.errors).indexOf(prop) > -1){
                if(prop === 'email')
                    if(this.state[prop].length < 1)
                        errors[prop] = this.state[prop].length < 1 ? `${prop} field is required!` : null;
                    else
                        errors[prop] = !validEmailRegex.test(this.state[prop]) ? `email should be valid!`: null;
                else
                    errors[prop] = this.state[prop].length < 1 ? `${prop} field is required!` : null;
                this.setState({errors, [prop]: this.state[prop]}, ()=> {
                    //console.log(errors)
                })
            }
        }
        this.setState({validationError: true})
        for(let prop in this.state.errors){
            if (this.state.errors[prop] !== null)
                return 0;
        }
        this.setState({loading: true})
        emailjs.send(process.env.SERVICE_ID, process.env.TEMPLATE_ID_CONTACT_US, this.state, process.env.USER_ID).then((response) => {
            this.setState({handleSubmitsuccess: true})
            this.setState({loading: false})
            this.setState({validationError: false})
        }, (err) => {
            this.setState({validationContent: err.text})
            this.setState({handleSubmitsuccess: false})
            this.setState({validationError: true})
        });
    }

    handleChange(event){
        const validEmailRegex = this.validEmailRegex();
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        })
        let errors = this.state.errors;
        if(Object.keys(this.state.errors).indexOf(name) > -1){
            if(name === 'email')
                if(value.length < 1)
                    errors[name] = value.length < 1 ? `${name} field is required!` : null;
                else
                    errors[name] = !validEmailRegex.test(value) ? `email should be valid!`: null;
            else
                errors[name] = value.length < 1 ? `${name} field is required!` : null;
            this.setState({errors, [name]: value}, ()=> {
                //console.log(errors)
            })
        }
    }
    
    render() {
        let data = this.props.allContactJson.edges;
        let d = []
        data.forEach((item)=>{
            d.push(item.node);
        })
        const price= d[0];
        return (
            <section id="pricing" className="section-padding bg-gray">
                <div className="container">
                    <div className="section-header text-center">
                        <h2 className="section-title wow fadeInDown">{price.title}</h2>
                        <p dangerouslySetInnerHTML={{__html: price.desc}}></p>
                    </div>
                    <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="contact-block">
                                <ScaleLoader
                                  css={override}
                                  size={15}
                                  color={"#123abc"}
                                  loading={this.state.loading}
                                />
                                { this.state.handleSubmitsuccess && 
                                    <UncontrolledAlert color="info">
                                        The email was successfully submitted!
                                    </UncontrolledAlert>
                                }
                                { this.state.validationError && 
                                    <UncontrolledAlert color="danger">
                                        {this.state.validationContent}
                                    </UncontrolledAlert>
                                }
                                  <form style={{background: '#fff',padding: '30px',borderRadius: '5px'}} 
                                      onSubmit={this.handleSubmit} className={this.state.loading ? 'loading-state' : ''}>
                                    <div className="row">
                                          <div className="col-md-6">
                                            <div className="form-group">
                                                <label className={`form-group label ${this.state.errors['first_name'] ? 'input-label-error' : ''}`}>
                                                    First Name
                                                </label>
                                                <input type="text" 
                                                    className={`form-control ${this.state.errors['first_name'] ? 'error' : ''}`} 
                                                    name="first_name" 
                                                    value={this.state.first_name}
                                                    onChange={this.handleChange}
                                                />
                                                <span className={this.state.errors['first_name'] ? 'error' : ''}> 
                                                    {this.state.errors['first_name']}
                                                </span>
                                            </div>                                 
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group">
                                                <label className={`form-group label ${this.state.errors['last_name'] ? 'input-label-error' : ''}`}>
                                                    Last Name
                                                </label>
                                                <input type="text" 
                                                    className={`form-control ${this.state.errors['last_name'] ? 'error' : ''}`}  
                                                    name="last_name" value={this.state.last_name} 
                                                    onChange={this.handleChange}
                                                />
                                                <span className={this.state.errors['last_name'] ? 'error' : ''}> 
                                                    {this.state.errors['last_name']}
                                                </span>
                                            </div>                                 
                                          </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className={`form-group label ${this.state.errors['email'] ? 'input-label-error' : ''}`}>
                                                    Email
                                                </label>
                                                <input type="text"
                                                    className={`form-control ${this.state.errors['email'] ? 'error' : ''}`} 
                                                    name="email" 
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                />
                                                <span className={this.state.errors['email'] ? 'error' : ''}> 
                                                    {this.state.errors['email']}
                                                </span>
                                            </div> 
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className={`form-group label ${this.state.errors['company_name'] ? 'input-label-error' : ''}`}>
                                                    Company Name
                                                </label>
                                                <input type="text" 
                                                    className={`form-control ${this.state.errors['company_name'] ? 'error' : ''}`}
                                                    name="company_name"
                                                    value={this.state.company_name}
                                                    onChange={this.handleChange}
                                                />
                                                <span className={this.state.errors['company_name'] ? 'error' : ''}> 
                                                    {this.state.errors['company_name']}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-group label">
                                                    Company website url
                                                </label>
                                                <input type="text" 
                                                    className="form-control"
                                                    name="company_website_url" 
                                                    value={this.state.company_website_url}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-group label">
                                                    Amazon store url
                                                </label>
                                                <input type="text" 
                                                    className="form-control"
                                                    name="amazon_store_url"
                                                    value={this.state.amazon_store_url}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-group label">
                                                    eBay store url
                                                </label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    name="ebay_store_url"
                                                    value={this.state.ebay_store_url}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group"> 
                                                <label className="form-group label">Notes</label>
                                                <textarea className="form-control" 
                                                    rows="6"
                                                    value={this.state.notes}
                                                    name="notes"
                                                    onChange={this.handleChange} 
                                                    placeholder="free text up to 2,000 characters."
                                                >
                                                </textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                            <div className="submit-button" style={{float: 'right'}}>
                                                <button className="btn btn-common" id="form-submit" type="submit">Submit</button>
                                                <div id="msgSubmit" className="h3 text-center hidden"></div> 
                                                <div className="clearfix"></div> 
                                            </div>
                                        </div>
                                    </div>            
                                </form>
                            </div>
                          </div>
                    </div>
                </div>
            </section>
           )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query {
                allContactJson {
                    edges {
                        node {
                            title
                            desc
                            label
                            contacts {
                                icon
                                label
                            }
                        }
                    }
                }
            }
        `}
        render={({ allContactJson }) => <Pricing allContactJson={allContactJson} {...props} />}
    />
)