import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import { useStaticQuery, graphql } from "gatsby"
import Avatar from '../images/avatar.png';
const Testimonial = ()=>{
	const query = useStaticQuery(graphql`
		query {
			allStrapiTestimonials {
			    edges {
					node {
						id
						client_name
						feedback
						company_name
						client_image {
						  	childImageSharp {
						    	fluid {
						      		src
						    	}
						  	}
						}
					}
			    }
			}
		}
		`
	)
	let testimonials  = [];
	query.allStrapiTestimonials.edges.forEach(({node})=>{
		testimonials.push(node);
	})
	return(
		<section id="testimonial" className="testimonial section-padding">
		  	<div className="overlay"></div>
		  	<div className="container">
		    	<div className="row justify-content-center">
		      		<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
		      			<div id="testimonials" className="owl-carousel wow fadeInUp">
				        	<Carousel autoPlay={false} infiniteLoop={true} showThumbs={false} showIndicators={false} showArrows={true} showStatus={false}>
				       			{
				       				testimonials.map((item, index)=>{
				       					return (
				       						<div className="item" style={{
				       							textAlign: 'center'
				       						}} key={`key_${index}`}>
						       					<img src={item.client_image ? item.client_image.childImageSharp.fluid.src : Avatar} alt="" style={{
								       				textAlign: 'center',
								       				position: 'relative',
								       				margin: '15px 15px 15px 0',
								       				borderRadius: '50%',
							       				    display: 'inline-block',
							       				    width: '100px',
							       				    padding: '7px',
							       				    height: '100px'
						       					}}/>
						       			  		<div className="info">
							       			    	<h2 style={{
														fontSize: '16px',
														fontWeight: '700',
														textTransform: 'uppercase',
														lineHeight: '30px',
														margin: '0',
							       			    	}}>
							       			    		<a href="#" style={{color: '#fff'}}>{item.client_name}</a>
							       			    	</h2>
							       			    	<h3 style={{
							       			    		margin: '0',
						       			    		    clear: 'both',
						       			    		    fontSize: '14px',
						       			    		    fontWeight: '600',
						       			    		    lineHeight: '26px',
						       			    		    marginBottom: '10px',
							       			    	}}>
							       			    		<a href="#" style={{color: '#fff'}}>{item.company_name}</a>
							       			    	</h3>
						       			  		</div>
						       			  		<div className="content" style={{overFlow: 'hidden'}}>
						       			    		<p  style={{width: '100%'}} className="description">{item.feedback}</p>
						       			  		</div>
				       						</div>
				       					)
				       				})
				       			}
				        	</Carousel>
		      			</div>
		      		</div>
		    	</div>
		  	</div>
		</section>
	)
}

export default Testimonial